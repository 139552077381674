/**
 * Item edit dialog controller
 */

(function() {
  'use strict';

  angular
    .module('app.itemDetail')
    .controller('ItemEditController', ItemEditController);

  ItemEditController.$inject = ['$mdDialog', '$window', 'itemService'];

  function ItemEditController($mdDialog, $window, itemService) {
    var vm = this;

    vm.cancel = cancel;
    vm.currentName = vm.item.name;
    vm.submit = submit;

    /**
     * Closes the dialog
     */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * Submits the form
     */
    function submit() {
      // Make sure the form is valid
      if (!vm.form.$valid) {
        // Focus the first invalid field
        $window.document.querySelector('input.ng-invalid, textarea.ng-invalid').focus();

        return;
      }

      vm.submitting = true;
      vm.error = false;

      // Call the API
      itemService
        .updateItem(vm.customerId, vm.item.id, vm.item)
        .then(function() {
          $mdDialog.hide(vm.item);
        })
        .catch(function(response) {
          // Show an error message and scroll to the top of the form so that it is visible
          vm.error = 'An unexpected error occurred. Please try again later.';
          $window.document.querySelector('#item-edit md-dialog-content').scrollTop = 0;

          vm.submitting = false;
        });
    }
  }
})();
