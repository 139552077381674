/**
 * Item page component
 */

(function() {
  'use strict';

  angular
    .module('app.itemDetail')
    .component('itemDetail', {
      bindings: {
        customerId: '<',
        item: '<',
        onItemDeleted: '&'
      },
      controller: ItemDetailController,
      controllerAs: 'vm',
      templateUrl: 'item-detail/item-detail.template.html'
    });

  ItemDetailController.$inject = [
    '$mdDialog',
    '$mdToast',
    '$q',
    '$scope',
    '$state',
    '$timeout',
    '$window',
    'itemService',
    'photoService',
    'valuationService'
  ];

  function ItemDetailController(
    $mdDialog,
    $mdToast,
    $q,
    $scope,
    $state,
    $timeout,
    $window,
    itemService,
    photoService,
    valuationService
  ) {
    var vm = this;

    vm.addValuation = addValuation;
    vm.deletePhotos = deletePhotos;
    vm.deleteValuation = deleteValuation;
    vm.deleteValuations = deleteValuations;
    vm.downloadValuation = downloadValuation;
    vm.edit = edit;
    vm.getIcon = getIcon;
    vm.photoUploads = [];
    vm.remove = remove;
    vm.uploadPhotos = uploadPhotos;
    vm.viewPhoto = viewPhoto;

    activate();

    /**
     * Sets up the controller
     */
    function activate() {
      // Update the heading and title when the item's name is updated
      $scope.$watch('vm.item.name', function(updatedName, currentName) {
        $state.current.data.title = updatedName;
        $window.document.title = updatedName + ' - Phenix Echo';
      });
    }

    /**
     * Opens a dialog for uploading a valuation
     */
    function addValuation(event) {
      $mdDialog
        .show({
          controller: 'ItemValuationAddController',
          controllerAs: 'vm',
          locals: {
            customerId: vm.customerId,
            itemId: vm.item.id
          },
          bindToController: true,
          templateUrl: 'item-detail/valuation-add/valuation-add.template.html',
          targetEvent: event,
          escapeToClose: false
        })
        .then(function(valuation) {
          vm.item.valuations.push(valuation);

          // Wait to make sure the toast notification animates properly
          return $timeout(500);
        })
        .then(function() {
          // Show a toast notification
          $mdToast.showSimple('Valuation added');
        })
        .catch(function() {
          // Add cancelled
        });
    }

    /**
     * Shows a confirm dialog for deleting the item's photos
     */
    function deletePhotos(event) {
      var confirm = $mdDialog
        .confirm()
        .textContent('Delete all photos for ' + vm.item.name + '?')
        .targetEvent(event)
        .ok('Delete all')
        .cancel('Cancel');

      $mdDialog
        .show(confirm)
        .then(function() {
          return photoService.deletePhotos(vm.customerId, vm.item.id);
        })
        .then(function() {
          vm.item.photos = [];

          // Show a toast notification
          $mdToast.showSimple('All photos deleted');
        })
        .catch(function() {
          // Delete cancelled
        });
    }

    /**
     * Shows a confirm dialog for deleting a valuation
     */
    function deleteValuation(event, valuation) {
      var confirm = $mdDialog
        .confirm()
        .textContent('Delete valuation?')
        .targetEvent(event)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog
        .show(confirm)
        .then(function() {
          return valuationService.deleteValuation(vm.customerId, vm.item.id, valuation.id);
        })
        .then(function() {
          // Remove the valuation from the item
          for (var i = 0; i < vm.item.valuations.length; i++) {
            if (vm.item.valuations[i].id === valuation.id) {
              vm.item.valuations.splice(i, 1);

              break;
            }
          }

          $mdToast.showSimple('Valuation deleted');
        })
        .catch(function() {
          // Delete cancelled
        });
    }

    /**
     * Shows a confirm dialog for deleting the item's valuations
     */
    function deleteValuations(event) {
      var confirm = $mdDialog
        .confirm()
        .textContent('Delete all valuations for ' + vm.item.name + '?')
        .targetEvent(event)
        .ok('Delete all')
        .cancel('Cancel');

      $mdDialog
        .show(confirm)
        .then(function() {
          return valuationService.deleteValuations(vm.customerId, vm.item.id);
        })
        .then(function() {
          vm.item.valuations = [];

          // Show a toast notification
          $mdToast.showSimple('All valuations deleted');
        })
        .catch(function() {
          // Delete cancelled
        });
    }

    /**
     * Downloads a valuation
     */
    function downloadValuation(valuation, disposition) {
      valuationService.downloadValuation(vm.customerId, vm.item.id, valuation.id, disposition);
    }

    /**
     * Opens a dialog for editing the item
     */
    function edit(event) {
      $mdDialog
        .show({
          controller: 'ItemEditController',
          controllerAs: 'vm',
          locals: {
            customerId: vm.customerId,
            item: angular.copy(vm.item)
          },
          bindToController: true,
          templateUrl: 'item-detail/edit/edit.template.html',
          targetEvent: event,
          escapeToClose: false
        })
        .then(function(updatedItem) {
          vm.item = updatedItem;

          // Show a toast notification
          $mdToast.showSimple('Changes saved');
        })
        .catch(function() {
          // Edit cancelled
        });
    }

    /**
     * Gets the item's placeholder icon
     */
    function getIcon() {
      return itemService.getItemIcon(vm.item.type);
    }

    /**
     * Shows a confirm dialog for deleting the item
     */
    function remove(event) {
      var confirm = $mdDialog
        .confirm()
        .textContent('Delete ' + vm.item.name + '?')
        .targetEvent(event)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog
        .show(confirm)
        .then(function() {
          vm.onItemDeleted({ item: vm.item });

          return itemService.deleteItem(vm.customerId, vm.item.id);
        })
        .then(function() {
          return $state.go('app.items', { customerId: vm.customerId });
        })
        .then(function() {
          // Wait to make sure the toast notification animates properly
          return $timeout(500);
        })
        .then(function() {
          // Show a toast notification
          $mdToast.showSimple('Jewellery deleted');
        })
        .catch(function() {
          // Delete cancelled
        });
    }

    /**
     * Uploads photos and adds them to the item
     */
    function uploadPhotos(event) {
      // Make sure the photo files are valid
      if (!vm.photoForm.$valid) {
        if (vm.photoForm.photos.$error.maxFiles) {
          var error = 'Please select 10 photos or fewer';
        } else if (vm.photoForm.photos.$error.maxSize) {
          var error = 'Photos must be 10 MB or smaller';
        } else if (vm.photoForm.photos.$error.pattern) {
          var error = 'Invalid photo';
        } else if (vm.photoForm.photos.$error.minHeight || vm.photoForm.photos.$error.minWidth) {
          var error = 'Photos must be 500 px by 500 px or larger';
        } else {
          var error = 'An unexpected error occurred. Please try again later.';
        }

        var alert = $mdDialog
          .alert()
          .textContent(error)
          .targetEvent(event)
          .ok('Dismiss');

        $mdDialog.show(alert);

        return;
      }

      var promises = [];

      // Upload the photos
      for (var i = 0; i < vm.photoFiles.length; i++) {
        promises.push(uploadPhoto(vm.photoFiles[i]));
      }

      $q
        .all(promises)
        .then(function() {
          // Show a toast notification
          $mdToast.showSimple(vm.photoFiles.length > 1 ? 'Photos added' : 'Photo added');
        });
    }

    /**
     * Opens a dialog with a photo
     */
    function viewPhoto(event, photo) {
      // Make sure a photo dialog isn't already open or opening
      if (vm.viewingPhoto) {
        return;
      }

      vm.viewingPhoto = true;

      $mdDialog
        .show({
          controller: 'ItemPhotoViewController',
          controllerAs: 'vm',
          locals: {
            customerId: vm.customerId,
            itemId: vm.item.id
          },
          resolve: {
            photo: function() {
              // Check if the photo's data has already been loaded
              if (photo.data) {
                return photo;
              }

              photo.resolving = true;

              // Call the API
              return photoService
                .getPhotoData(vm.customerId, vm.item.id, photo.id)
                .then(function(photoData) {
                  photo.data = photoData;
                  photo.resolving = false;

                  return photo;
                });
            }
          },
          bindToController: true,
          templateUrl: 'item-detail/photo-view/photo-view.template.html',
          targetEvent: event,
          clickOutsideToClose: true
        })
        .then(function(action) {
          if (action === 'delete') {
            deletePhoto(event, photo);
          }
        })
        .catch(function() {
          // Dialog dismissed
        })
        .finally(function() {
          vm.viewingPhoto = false;
        });
    }

    /**
     * Deletes a photo and removes it from the item
     */
    function deletePhoto(event, photo) {
      var confirm = $mdDialog
        .confirm()
        .textContent('Delete photo?')
        .targetEvent(event)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog
        .show(confirm)
        .then(function() {
          return photoService.deletePhoto(vm.customerId, vm.item.id, photo.id);
        })
        .then(function() {
          // Remove the photo from the item
          for (var i = 0; i < vm.item.photos.length; i++) {
            if (vm.item.photos[i].id === photo.id) {
              vm.item.photos.splice(i, 1);

              break;
            }
          }
        })
        .catch(function() {
          // Delete cancelled
        });
    }

    /**
     * Uploads a photo and adds it to the item
     */
    function uploadPhoto(photoFile) {
      vm.photoUploads.push(photoFile);

      return photoService
        .uploadPhoto(vm.customerId, vm.item.id, photoFile)
        .then(function(photo) {
          vm.photoUploads.splice(vm.photoUploads.indexOf(photoFile), 1);
          vm.item.photos.push(photo);
        });
    }
  }
})();
