/**
 * Customer service
 */

(function() {
  'use strict';

  angular
    .module('app.core.customer')
    .factory('customerService', customerService);

  customerService.$inject = ['$resource'];

  function customerService($resource) {
    var Customer = $resource(
      'https://api.echo.phenixjewellery.com/customers/:customerId',
      null,
      { update: { method: 'PATCH' } }
    );

    return {
      createCustomer: createCustomer,
      getCustomer: getCustomer,
      isEmailAvailable: isEmailAvailable,
      updateCustomer: updateCustomer,
      updateCustomerPassword: updateCustomerPassword
    };

    /**
     * Calls the API to create a customer
     */
    function createCustomer(customer) {
      return Customer
        .save(customer)
        .$promise;
    }

    /**
     * Gets a customer from the API
     */
    function getCustomer(customerId, includeItems, includeItemThumbnails, includeItemPhotos) {
      includeItems = angular.isDefined(includeItems) ? includeItems : false;
      includeItemThumbnails = angular.isDefined(includeItemThumbnails) ? includeItemThumbnails : false;
      includeItemPhotos = angular.isDefined(includeItemPhotos) ? includeItemPhotos : false;

      return Customer
        .get({
          customerId: customerId,
          includeItems: includeItems,
          includeItemThumbnails: includeItemThumbnails,
          includeItemPhotos: includeItemPhotos
        })
        .$promise;
    }

    /**
     * Calls the API to check if an email address is available for a customer to use
     */
    function isEmailAvailable(email) {
      return Customer
        .query({ email: email })
        .$promise
        .then(function(customers) {
          return customers.length === 0;
        });
    }

    /**
     * Calls the API to update a customer
     */
    function updateCustomer(customerId, updatedCustomer) {
      // Get the customer from the API
      return Customer
        .get({ customerId: customerId })
        .$promise
        .then(function(currentCustomer) {
          return Customer
            .update({ customerId: customerId }, createPatch(currentCustomer, updatedCustomer))
            .$promise;
        });
    }

    /**
     * Calls the API to update a customer's password
     */
    function updateCustomerPassword(customerId, updatedPassword) {
      return Customer
        .update({ customerId: customerId }, [{ op: 'replace', path: '/password', value: updatedPassword }])
        .$promise;
    }
  }

  /**
   * Creates a JSON Patch Document for updating a customer (see https://tools.ietf.org/html/rfc6902)
   */
  function createPatch(currentCustomer, updatedCustomer) {
    // Customer values that can be updated
    var values = [
      { path: '/name/first',    current: currentCustomer.name.first,    updated: updatedCustomer.name.first    },
      { path: '/name/last',     current: currentCustomer.name.last,     updated: updatedCustomer.name.last     },
      { path: '/email',         current: currentCustomer.email,         updated: updatedCustomer.email         },
      { path: '/marketing',     current: currentCustomer.marketing,     updated: updatedCustomer.marketing     }
    ];

    var operations = [];

    // Add an appropriate operation for each property
    angular.forEach(values, function(value) {
      var hasCurrentValue = angular.isDefined(value.current) && value.current !== '';
      var hasUpdatedValue = angular.isDefined(value.updated) && value.updated !== '';

      // Determine which operation to add
      if (!hasCurrentValue && hasUpdatedValue) {
        operations.push({ op: 'add', path: value.path, value: value.updated });
      } else if (hasCurrentValue && !hasUpdatedValue) {
        operations.push({ op: 'remove',  path: value.path });
      } else if (hasCurrentValue && hasUpdatedValue && value.current !== value.updated) {
        operations.push({ op: 'replace', path: value.path, value: value.updated });
      }
    });

    return operations;
  }
})();
