/**
 * Directive for checking if a value matches another
 */

(function() {
  'use strict';

  angular
    .module('app.core.match')
    .directive('phenixMatch', phenixMatch);

  function phenixMatch() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link
    };

    function link(scope, element, attributes, controller) {
      controller.$validators['phenix-match'] = function(modelValue) {
        if (controller.$isEmpty(modelValue) || controller.$isEmpty(attributes.phenixMatch)) return true;

        return modelValue === attributes.phenixMatch;
      };

      // Validate the form whenever the specified value changes
      attributes.$observe('phenixMatch', function() {
        controller.$validate();
      });
    }
  }
})();
