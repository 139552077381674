/**
 * Item add dialog controller
 */

(function() {
  'use strict';

  angular
    .module('app.layout')
    .controller('ItemsItemAddController', ItemsItemAddController);

  ItemsItemAddController.$inject = ['$mdDialog', '$window', 'itemService'];

  function ItemsItemAddController($mdDialog, $window, itemService) {
    var vm = this;

    vm.cancel = cancel;
    vm.item = { hasValuation: false };
    vm.submit = submit;
    vm.types = getTypes();

    /**
     * Closes the dialog
     */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * Submits the form
     */
    function submit() {
      // Make sure the form is valid
      if (!vm.form.$valid) {
        // Focus the first invalid field
        $window.document.querySelector('md-select.ng-invalid, input.ng-invalid, textarea.ng-invalid').focus();

        return;
      }

      vm.submitting = true;
      vm.error = false;

      // Call the API
      itemService
        .createItem(vm.customerId, vm.item)
        .then(function(item) {
          $mdDialog.hide(item);
        })
        .catch(function() {
          // Show an error message and scroll to the top of the form so that it is visible
          vm.error = 'An unexpected error occurred. Please try again later.';
          $document.querySelector('#item-add md-dialog-content').scrollTop = 0;

          vm.submitting = false;
        });
    }
  }

  /**
   * Gets the different types of jewellery
   */
  function getTypes() {
    return [
      'Bangle',
      'Bracelet',
      'Chain',
      'Pair of cufflinks',
      'Pair of earrings',
      'Pendant',
      'Ring'
    ];
  }
})();
