/**
 * Item service
 */

(function() {
  'use strict';

  angular
    .module('app.core.item')
    .factory('itemService', itemService);

  itemService.$inject = ['$resource'];

  function itemService($resource) {
    var Item = $resource('https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId', null, {
      getThumbnail: {
        method: 'GET',
        url: 'https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/thumbnail'
      },
      update: { method: 'PATCH' },
      updateDetails: {
        method: 'PUT',
        url: 'https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/details'
      }
    });

    return {
      createItem: createItem,
      deleteItem: deleteItem,
      getItem: getItem,
      getItemIcon: getItemIcon,
      getItemThumbnail: getItemThumbnail,
      updateItem: updateItem,
    };

    /**
     * Calls the API to create an item
     */
    function createItem(customerId, item) {
      return Item
        .save({ customerId: customerId }, item)
        .$promise;
    }

    /**
     * Calls the API to delete an item
     */
    function deleteItem(customerId, itemId) {
      return Item
        .delete({
          customerId: customerId,
          itemId: itemId
        })
        .$promise;
    }

    /**
     * Gets an item from the API
     */
    function getItem(customerId, itemId, includeThumbnail, includePhotos, includeValuations) {
      includeThumbnail = angular.isDefined(includeThumbnail) ? includeThumbnail : false;
      includePhotos = angular.isDefined(includePhotos) ? includePhotos : false;
      includeValuations = angular.isDefined(includeValuations) ? includeValuations : false;

      return Item
        .get({
          customerId: customerId,
          itemId: itemId,
          includeThumbnail: includeThumbnail,
          includePhotos: includePhotos,
          includeValuations: includeValuations
        })
        .$promise;
    }

    /**
     * Gets an item type's placeholder icon
     */
    function getItemIcon(itemType) {
      switch (itemType) {
        case 'Bracelet':
          return 'jewellery:chain';
        case 'Pair of cufflinks':
          return 'jewellery:cufflinks';
        case 'Pair of earrings':
          return 'jewellery:earrings';
        default:
          return 'jewellery:' + itemType.toLowerCase();
      }
    }

    /**
     * Gets an item's thumbnail
     */
    function getItemThumbnail(customerId, itemId) {
      return Item
        .getThumbnail({
          customerId: customerId,
          itemId: itemId
        })
        .$promise
        .then(function(response) {
          return response.thumbnail;
        });
    }

    /**
     * Calls the API to update an item
     */
    function updateItem(customerId, itemId, updatedItem) {
      return Item
        .get({
          customerId: customerId,
          itemId: itemId
        })
        .$promise
        .then(function(currentItem) {
          return Item
            .update(
              {
                customerId: customerId,
                itemId: itemId
              },
              createPatch(currentItem, updatedItem)
            )
            .$promise;
        });
    }

    /**
     * Creates a JSON Patch Document for updating an item (see https://tools.ietf.org/html/rfc6902)
     */
    function createPatch(currentItem, updatedItem) {
      // Item values that can be updated
      var values = [
        { path: '/name',            current: currentItem.name,            updated: updatedItem.name            },
        { path: '/description',     current: currentItem.description,     updated: updatedItem.description     },
        { path: '/placeOfPurchase', current: currentItem.placeOfPurchase, updated: updatedItem.placeOfPurchase },
        { path: '/scanned',         current: currentItem.scanned,         updated: updatedItem.scanned         }
      ];

      var operations = [];

      // Add an appropriate operation for each property
      angular.forEach(values, function(value) {
        var hasCurrentValue = angular.isDefined(value.current) && value.current !== '';
        var hasUpdatedValue = angular.isDefined(value.updated) && value.updated !== '';

        // Determine which operation to add
        if (!hasCurrentValue && hasUpdatedValue) {
          operations.push({ op: 'add', path: value.path, value: value.updated });
        } else if (hasCurrentValue && !hasUpdatedValue) {
          operations.push({ op: 'remove',  path: value.path });
        } else if (hasCurrentValue && hasUpdatedValue && value.current !== value.updated) {
          operations.push({ op: 'replace', path: value.path, value: value.updated });
        }
      });

      return operations;
    }
  }
})();
