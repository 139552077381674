/**
 * Item page configuration
 */

(function() {
  'use strict';

  angular
    .module('app.itemDetail')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.item', {
      url: '/customers/{customerId}/items/{itemId}',
      component: 'itemDetail',
      resolve: {
        customerId: getCustomerId,
        item: getItem
      },
      data: { requiresAuthentication: true }
    });
  }

  getCustomerId.$inject = ['$stateParams'];

  function getCustomerId($stateParams) {
    return $stateParams.customerId;
  }

  getItem.$inject = ['$stateParams', 'itemService'];

  function getItem($stateParams, itemService) {
    return itemService.getItem($stateParams.customerId, $stateParams.itemId, true, true, true);
  }
})();
