/**
 * Login page module
 */

(function() {
  'use strict';

  angular.module('app.login', [
    'app.core.authentication',
    'ngMaterial',
    'ngMessages',
    'ui.router'
  ]);
})();
