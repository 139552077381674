/**
 * Account edit dialog controller
 */

(function() {
  'use strict';

  angular
    .module('app.account')
    .controller('AccountEditController', AccountEditController);

  AccountEditController.$inject = ['$mdDialog', '$window', 'customerService'];

  function AccountEditController($mdDialog, $window, customerService) {
    var vm = this;

    vm.cancel = cancel;
    vm.currentEmail = vm.customer.email;
    vm.submit = submit;

    /**
     * Closes the dialog
     */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * Submits the form
     */
    function submit() {
      // Make sure the form is valid
      if (!vm.form.$valid) {
        // Focus the first invalid field
        $window.document.querySelector('input.ng-invalid').focus();

        return;
      }

      vm.submitting = true;
      vm.error = false;

      // Call the API
      customerService
        .updateCustomer(vm.customer.id, vm.customer)
        .then(function() {
          $mdDialog.hide(vm.customer);
        })
        .catch(function() {
          // Scroll to the top of the form so that the error message is visible
          vm.error = 'An unexpected error occurred. Please try again later.';
          $window.document.querySelector('#account-edit md-dialog-content').scrollTop = 0;

          vm.submitting = false;
        });
    }
  }
})();
