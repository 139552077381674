/**
 * App layout component
 */

(function() {
  'use strict';

  angular
    .module('app.layout')
    .component('layout', {
      bindings: { customer: '<' },
      controller: LayoutController,
      controllerAs: 'vm',
      templateUrl: 'layout/layout.template.html'
    });

  LayoutController.$inject = [
    '$mdColors',
    '$mdDialog',
    '$mdSidenav',
    '$mdToast',
    '$scope',
    '$state',
    '$timeout',
    '$transitions',
    'authenticationService',
    'itemService'
  ];

  function LayoutController(
    $mdColors,
    $mdDialog,
    $mdSidenav,
    $mdToast,
    $scope,
    $state,
    $timeout,
    $transitions,
    authenticationService,
    itemService
  ) {
    var vm = this;

    vm.$state = $state;
    vm.activeListItemColor = $mdColors.getThemeColor('default-background-hue-2');
    vm.addItem = addItem;
    vm.getItemIcon = getItemIcon;
    vm.logout = logout;
    vm.toggleSidenav = toggleSidenav;
    vm.deleteItem = deleteItem;
    vm.updateCustomer = updateCustomer;
    vm.updateItem = updateItem;

    activate();

    /**
     * Sets up the controller
     */
    function activate() {
      // Register a transition hook for displaying a progress indicator while pages resolve
      var deregisterOnStart = $transitions.onStart(
        {
          to: 'app.**',
          from: 'app.**'
        },
        function(transition) {
          vm.resolving = true;

          transition
            .promise
            .finally(function() {
              vm.resolving = false;
            });
        }
      );

      // Deregister the transition hook when done with the controller
      $scope.$on('$destroy', function() {
        deregisterOnStart();
      });
    }

    /**
     * Opens a dialog for creating an item
     */
    function addItem(event) {
      $mdDialog
        .show({
          controller: 'ItemAddController',
          controllerAs: 'vm',
          locals: { customerId: vm.customer.id },
          bindToController: true,
          templateUrl: 'layout/item-add/item-add.template.html',
          targetEvent: event,
          escapeToClose: false
        })
        .then(function(item) {
          vm.customer.items.push(item);

          return $state.go('app.item', { customerId: vm.customer.id, itemId: item.id });
        })
        .then(function() {
          // Wait to make sure the toast notification animates properly
          return $timeout(500);
        })
        .then(function() {
          $mdToast.showSimple('Jewellery added');
        })
        .catch(function() {
          // Item add cancelled
        });
    }

    /**
     * Deletes an item from the authenticated customer's items
     */
    function deleteItem(item) {
      // Find the item in the customer's items
      for (var i = 0; i < vm.customer.items.length; i++) {
        if (vm.customer.items[i].id === item.id) {
          vm.customer.items.splice(i, 1);

          break;
        }
      }
    }

    /**
     * Gets an item type's placeholder icon
     */
    function getItemIcon(itemType) {
      return itemService.getItemIcon(itemType);
    }

    /**
     * Logs the user out
     */
    function logout() {
      authenticationService.logout();

      // Redirect to the login page
      $state
        .go('login')
        .then(function() {
          // Wait to make sure the toast notification animates properly
          return $timeout(500);
        })
        .then(function() {
          // Show a toast notification
          $mdToast.showSimple('Logged out');
        });
    }

    /**
     * Opens or closes the sidenav
     */
    function toggleSidenav() {
      $mdSidenav('sidenav').toggle();
    }

    /**
     * Updates the authenticated customer
     */
    function updateCustomer(updatedCustomer) {
      vm.customer.name = updatedCustomer.name;
    }

    /**
     * Updates an item in the authenticated customer's items
     */
    function updateItem(updatedItem) {
      for (var i = 0; i < vm.customer.items.length; i++) {
        if (vm.customer.items[i].id === updatedItem.id) {
          vm.customer.items[i].name = updatedItem.name;
          vm.customer.items[i].thumbnail = updatedItem.thumbnail;

          break;
        }
      }
    }
  }
})();
