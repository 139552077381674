/**
 * Account page component
 */

(function() {
  'use strict';

  angular
    .module('app.account')
    .component('account', {
      bindings: {
        customer: '<',
        onCustomerUpdated: '&'
      },
      controller: AccountController,
      controllerAs: 'vm',
      templateUrl: 'account/account.template.html'
    });

  AccountController.$inject = ['$mdDialog', '$mdToast'];

  function AccountController($mdDialog, $mdToast) {
    var vm = this;

    vm.changePassword = changePassword;
    vm.edit = edit;

    /**
     * Opens a dialog for changing the authenticated customer's password
     */
    function changePassword(event) {
      $mdDialog
        .show({
          controller: 'AccountPasswordChangeController',
          controllerAs: 'vm',
          locals: { customerId: vm.customer.id },
          bindToController: true,
          templateUrl: 'account/password-change/password-change.template.html',
          targetEvent: event,
          escapeToClose: false
        })
        .then(function() {
          $mdToast.showSimple('Password changed');
        })
        .catch(function() {
          // Password change cancelled
        });
    }

    /**
     * Opens a dialog for editing the authenticated customer
     */
    function edit(event) {
      $mdDialog
        .show({
          controller: 'AccountEditController',
          controllerAs: 'vm',
          locals: { customer: angular.copy(vm.customer) },
          bindToController: true,
          templateUrl: 'account/edit/edit.template.html',
          targetEvent: event,
          escapeToClose: false
        })
        .then(function(updatedCustomer) {
          vm.customer = updatedCustomer;
          vm.onCustomerUpdated({ updatedCustomer: updatedCustomer });

          $mdToast.showSimple('Changes saved');
        })
        .catch(function() {
          // Edit cancelled
        });
    }
  }
})();
