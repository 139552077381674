/**
 * Registration page component
 */

(function() {
  'use strict';

  angular
    .module('app.registration')
    .component('registration', {
      controller: RegistrationController,
      controllerAs: 'vm',
      templateUrl: 'registration/registration.template.html'
    });

  RegistrationController.$inject = ['$document', '$state', 'authenticationService', 'customerService'];

  function RegistrationController($document, $state, authenticationService, customerService) {
    var vm = this;

    vm.customer = { marketing: false };
    vm.submit = submit;

    /**
     * Submits the form
     */
    function submit() {
      // Make sure the form is valid
      if (!vm.form.$valid) {
        // Focus the first invalid field
        document.querySelector('input.ng-invalid').focus();

        return;
      }

      vm.submitting = true;
      vm.error = false;

      // Call the API
      customerService
        .createCustomer(vm.customer)
        .then(function() {
          return authenticationService.login(vm.customer.email, vm.customer.password);
        })
        .then(function() {
          // Redirect to the item list
          $state.go('app.items');
        })
        .catch(function(error) {
          // Show an error message and scroll to the top of the form so that it's visible
          vm.error = 'An unexpected error occured. Please try again later.';
          $document.find('md-content')[0].scrollTop = 0;

          vm.submitting = false;
        });
    }
  }
})();
