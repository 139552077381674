/**
 * Photo service module
 */

(function() {
  'use strict';

  angular.module('app.core.photo', [
    'ngResource'
  ]);
})();
