/**
 * Item valuation add dialog controller
 */

(function() {
  'use strict';

  angular
    .module('app.itemDetail')
    .controller('ItemValuationAddController', ItemValuationAddController);

  ItemValuationAddController.$inject = ['$mdDialog', '$window', 'valuationService'];

  function ItemValuationAddController($mdDialog, $window, valuationService) {
    var vm = this;

    vm.cancel = cancel;
    vm.submit = submit;

    /**
     * Closes the dialog
     */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * Submits the form
     */
    function submit() {
      // Make sure the form is valid
      if (!vm.form.$valid) {
        // Focus the first invalid field
        $window.document.querySelector('input.ng-invalid, textarea.ng-invalid').focus();

        return;
      }

      vm.submitting = true;
      vm.error = false;

      // Call the API
      valuationService
        .uploadValuation(vm.customerId, vm.itemId, vm.valuationFile, vm.valuationNotes)
        .then(function(valuation) {
          $mdDialog.hide(valuation);
        })
        .catch(function(response) {
          // Show an error message and scroll to the top of the form so that it is visible
          vm.error = 'An unexpected error occurred. Please try again later.';
          $window.document.querySelector('#item-valuation-add md-dialog-content').scrollTop = 0;

          vm.submitting = false;
        });
    }
  }
})();
