/**
 * Item page module
 */

(function() {
  'use strict';

  angular.module('app.itemDetail', [
    'app.core.item',
    'app.core.photo',
    'app.core.valuation',
    'ngFileUpload',
    'ngMaterial',
    'ngMessages',
    'ui.router'
  ])
})();
