/**
 * App layout module
 */

(function() {
  'use strict';

  angular.module('app.layout', [
    'app.core.authentication',
    'app.core.item',
    'ngMaterial',
    'ui.router'
  ])
})();
