/**
 * Authentication service module
 */

(function() {
  'use strict';

  angular.module('app.core.authentication', [
    'angular-jwt',
    'angular-storage'
  ]);
})();
