/**
 * App layout configuration
 */

(function() {
  'use strict';

  angular
    .module('app.layout')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app', {
      component: 'layout',
      abstract: true,
      resolve: { customer: getCustomer }
    });
  }

  getCustomer.$inject = ['authenticationService', 'customerService'];

  function getCustomer(authenticationService, customerService) {
    return customerService.getCustomer(authenticationService.getCustomerId(), true, true);
  }
})();
