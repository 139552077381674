/**
 * Main app run block
 */

(function() {
  'use strict';

  angular
    .module('app')
    .run(run);

  run.$inject = ['$mdDialog', '$state', '$transitions', '$window', 'authenticationService'];

  function run($mdDialog, $state, $transitions, $window, authenticationService) {
    // Actions to perform when transitioning to another page
    $transitions.onStart({}, function(transition) {
      // Close any open dialogs
      $mdDialog.cancel();

      var to = transition.to();

      // Redirect unauthenticated users to the login page
      if (to.data && to.data.requiresAuthentication && !authenticationService.isAuthenticated()) {
        return $state.target('login');
      }

      // Change the document title
      $window.document.title = to.data && to.data.title ? to.data.title + ' - Phenix Echo' : 'Phenix Echo';
    });

    // Handle transition errors
    $transitions.onError({}, function(transition) {
      if (transition.error().status === 401) {
        authenticationService.logout();
        $state.go('app.items');
      }
    });

    // Log routing errors
    $state.defaultErrorHandler(function(error) {
      console.error(error);
    });
  }
})();
