/**
 * App layout run block
 */

(function() {
  'use strict';

  angular
    .module('app.login')
    .run(run);

  run.$inject = ['$mdSidenav', '$transitions', '$window'];

  function run($mdSidenav, $transitions, $window) {
    // Close the sidenav when transitioning between pages
    $transitions.onStart(
      {
        to: 'app.**',
        from: 'app.**'
      },
      function() {
        $mdSidenav('sidenav').close();

        // Blur the customer search field
        $window.document.activeElement.blur();
      }
    );
  }
})();
