/**
 * Valuation service
 */

(function() {
  'use strict';

  angular
    .module('app.core.valuation')
    .factory('valuationService', valuationService);

  valuationService.$inject = ['$resource', '$window', 'Upload'];

  function valuationService($resource, $window, Upload) {
    var Valuation = $resource('https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/valuations/:valuationId',
      null,
      {
        generateDownloadToken: {
          method: 'PUT',
          url: 'https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/valuations/:valuationId/download-token'
        },
        getData: {
          method: 'GET',
          url: 'https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/valuations/:valuationId/data'
        }
      }
    );

    return {
      deleteValuation: deleteValuation,
      deleteValuations: deleteValuations,
      downloadValuation: downloadValuation,
      uploadValuation: uploadValuation
    };

    /**
     * Calls the API to delete a valuation
     */
    function deleteValuation(customerId, itemId, valuationId) {
      return Valuation
        .delete({
          customerId: customerId,
          itemId: itemId,
          valuationId: valuationId
        })
        .$promise;
    }

    /**
     * Calls the API to delete an item's valuations
     */
    function deleteValuations(customerId, itemId) {
      return Valuation
        .delete({
          customerId: customerId,
          itemId: itemId
        })
        .$promise;
    }

    /**
     * Calls the API to generate a download token and downloads a valuation file
     */
    function downloadValuation(customerId, itemId, valuationId, disposition) {
      switch (disposition) {
        case 'attachment':
          return Valuation
            .generateDownloadToken(
              {
                customerId: customerId,
                itemId: itemId,
                valuationId: valuationId
              },
              {}
            )
            .$promise
            .then(function(downloadToken) {
              var url = 'https://api.echo.phenixjewellery.com/customers/'
                + customerId
                + '/items/'
                + itemId
                + '/valuations/'
                + valuationId
                + '/file?token='
                + downloadToken.token
                + '&disposition=attachment';

              // Redirect the current window to the file's URL
              $window.location.assign(url);
            });
        case 'inline':
          // Open a new window
          var newWindow = $window.open('about:blank', '_blank');

          return Valuation
            .generateDownloadToken(
              {
                customerId: customerId,
                itemId: itemId,
                valuationId: valuationId
              },
              {}
            )
            .$promise
            .then(function(downloadToken) {
              var url = 'https://api.echo.phenixjewellery.com/customers/'
                + customerId
                + '/items/'
                + itemId
                + '/valuations/'
                + valuationId
                + '/file?token='
                + downloadToken.token
                + '&disposition=inline';

              // Redirect the new window to the file's URL
              newWindow.location.href = url;
            });
      }
    }

    /**
     * Calls the API to upload a valuation
     */
    function uploadValuation(customerId, itemId, valuationFile, valuationNotes) {
      valuationFile.progress = 0;

      return Upload
        .upload({
          url: 'https://api.echo.phenixjewellery.com/customers/' + customerId + '/items/' + itemId + '/valuations',
          data: {
            valuation: valuationFile,
            notes: valuationNotes
          }
        })
        .then(
          function(response) {
            return response.data;
          },
          function(error) {
          },
          function(event) {
            valuationFile.progress = Math.min(100, parseInt(100.0 * event.loaded / event.total))
          }
        );
    }
  }
})();
