/**
 * Home page configuration
 */

(function() {
  'use strict';

  angular
    .module('app.index')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('index', {
      url: '/',
      component: 'index'
    });
  }
})();
