/**
 * Registration page run block
 */

(function() {
  'use strict';

  angular
    .module('app.registration')
    .run(run);

  run.$inject = ['$state', '$transitions', 'authenticationService'];

  function run($state, $transitions, authenticationService) {
    // Redirect authenticated users to the customer search page
    $transitions.onStart({ to: 'register' }, function(transition) {
      if (authenticationService.isAuthenticated()) return $state.target('app.items');
    });
  }
})();
