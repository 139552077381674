/**
 * Registration page configuration
 */

(function() {
  'use strict';

  angular
    .module('app.registration')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('register', {
      url: '/register',
      component: 'registration',
      data: { title: 'Register' }
    });
  }
})();
