/**
 * Item list page configuration
 */

(function() {
  'use strict';

  angular
    .module('app.itemList')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.items', {
      url: '/items',
      component: 'itemList',
      resolve: { customer: getCustomer },
      data: {
        title: 'Your jewellery',
        requiresAuthentication: true
      }
    });
  }

  getCustomer.$inject = ['authenticationService', 'customerService'];

  function getCustomer(authenticationService, customerService) {
    return customerService.getCustomer(authenticationService.getCustomerId(), true, true);
  }
})();
