/**
 * Item list page module
 */

(function() {
  'use strict';

  angular.module('app.itemList', [
    'app.core.customer',
    'ngMaterial',
    'ui.router'
  ])
})();
