/**
 * Directive for checking if a customer email address is available
 */

(function() {
  'use strict';

  angular
    .module('app.core.customer')
    .directive('phenixUniqueCustomerEmail', phenixUniqueCustomerEmail);

  phenixUniqueCustomerEmail.$inject = ['$q', 'customerService'];

  function phenixUniqueCustomerEmail($q, customerService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link
    };

    function link(scope, element, attributes, controller) {
      controller.$asyncValidators['phenix-unique-customer-email'] = function(modelValue) {
        // The email address is valid if it belongs to the current user
        if (attributes.phenixCurrentEmail && attributes.phenixCurrentEmail === modelValue) return $q.resolve(true);

        var deferred = $q.defer();

        // Call the API
        customerService
          .isEmailAvailable(modelValue)
          .then(function(isEmailAvailable) {
            isEmailAvailable ? deferred.resolve() : deferred.reject();
          })
          .catch(function(response) {
            deferred.resolve();
          });

        return deferred.promise;
      };
    }
  }
})();
