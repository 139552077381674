/**
 * Main app configuration
 */

(function() {
  'use strict';

  angular
    .module('app')
    .config(config);

  config.$inject = [
    '$locationProvider',
    '$httpProvider',
    '$mdIconProvider',
    '$mdThemingProvider',
    '$urlRouterProvider',
    'jwtOptionsProvider'
  ];

  function config(
    $locationProvider,
    $httpProvider,
    $mdIconProvider,
    $mdThemingProvider,
    $urlRouterProvider,
    jwtOptionsProvider)
  {
    // Get rid of the # in the URL
    $locationProvider.html5Mode(true);

    // Configure theme
    $mdThemingProvider
      .theme('default')
      .primaryPalette('blue')
      .accentPalette('pink');

    // Register icons
    $mdIconProvider
      .iconSet('jewellery', 'images/icons/jewellery-icons.svg')
      .iconSet('material', 'images/icons/material-icons.svg');

    // Get the authentication token from local storage when calling the API
    jwtOptionsProvider.config({
      tokenGetter: [
        'options',
        'store',
        function(options, store) {
          return store.get('token');
        }
      ],
      whiteListedDomains: ['localhost', 'api.echo.phenixjewellery.com']
    });
    $httpProvider.interceptors.push('jwtInterceptor');

    // Default state
    $urlRouterProvider.otherwise('/');
  }
})();
