/**
 * Main app module
 */

(function() {
  'use strict';

  angular.module('app', [
    'app.account',
    'app.core.authentication',
    'app.index',
    'app.itemDetail',
    'app.itemList',
    'app.layout',
    'app.login',
    'app.registration',
    'ngMaterial',
    'ui.router'
  ]);
})();
