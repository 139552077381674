/**
 * Login page component
 */

(function() {
  'use strict';

  angular
    .module('app.login')
    .component('login', {
      controller: LoginController,
      controllerAs: 'vm',
      templateUrl: 'login/login.template.html'
    });

  LoginController.$inject = ['$document', '$state', 'authenticationService'];

  function LoginController($document, $state, authenticationService) {
    var vm = this;

    vm.submit = submit;

    /**
     * Submits the form
     */
    function submit() {
      // Make sure the form is valid
      if (!vm.form.$valid) {
        // Focus the first invalid field
        document.querySelector('input.ng-invalid').focus();

        return;
      }

      vm.submitting = true;
      vm.error = false;

      // Call the API
      authenticationService
        .login(vm.credentials.email, vm.credentials.password)
        .then(function() {
          // Redirect to the customer search page
          $state.go('app.items');
        })
        .catch(function(response) {
          // Show an error message
          if (response.status && response.status === 401) {
            vm.error = 'Incorrect email address or password';
          } else {
            vm.error = 'An unexpected error occurred. Please try again later.';

            console.error(response);
          }

          // Scroll to the top of the form so that the error message is visible
          $document.find('md-content')[0].scrollTop = 0;

          vm.submitting = false;
        });
    }
  }
})();
