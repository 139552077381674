/**
 * Home page module
 */

(function() {
  'use strict';

  angular.module('app.index', [
    'ngMaterial',
    'ui.router'
  ])
})();
