/**
 * Valuation service module
 */

(function() {
  'use strict';

  angular.module('app.core.valuation', [
    'ngResource'
  ]);
})();
