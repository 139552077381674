/**
 * Account page configuration
 */

(function() {
  'use strict';

  angular
    .module('app.account')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('app.account', {
      url: '/account',
      component: 'account',
      resolve: { customer: getCustomer },
      data: {
        title: 'Your account',
        requiresAuthentication: true
      }
    });
  }

  getCustomer.$inject = ['authenticationService', 'customerService'];

  function getCustomer(authenticationService, customerService) {
    return customerService.getCustomer(authenticationService.getCustomerId());
  }
})();
