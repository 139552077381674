/**
 * Item list page component
 */

(function() {
  'use strict';

  angular
    .module('app.itemList')
    .component('itemList', {
      bindings: { customer: '<' },
      controller: ItemListController,
      controllerAs: 'vm',
      templateUrl: 'item-list/item-list.template.html'
    });

  ItemListController.$inject = ['$mdDialog', '$mdSidenav', '$mdToast', '$state', 'itemService'];

  function ItemListController($mdDialog, $mdSidenav, $mdToast, $state, itemService) {
    var vm = this;

    vm.addItem = addItem;
    vm.getItemIcon = getItemIcon;
    vm.toggleSidenav = toggleSidenav;

    /**
     * Opens a dialog for creating an item
     */
    function addItem(event) {
      $mdDialog
        .show({
          controller: 'ItemAddController',
          controllerAs: 'vm',
          locals: { customerId: vm.customer.id },
          bindToController: true,
          templateUrl: 'layout/item-add/item-add.template.html',
          targetEvent: event,
          escapeToClose: false
        })
        .then(function(item) {
          vm.customer.items.push(item);

          return $state.go('app.item', { customerId: vm.customer.id, itemId: item.id });
        })
        .then(function() {
          // Wait to make sure the toast notification animates properly
          return $timeout(500);
        })
        .then(function() {
          $mdToast.showSimple('Jewellery added');
        })
        .catch(function() {
          // Item add cancelled
        });
    }

    /**
     * Gets an item type's placeholder icon
     */
    function getItemIcon(itemType) {
      return itemService.getItemIcon(itemType);
    }

    /**
     * Opens or closes the sidenav
     */
    function toggleSidenav() {
      $mdSidenav('sidenav').toggle();
    }
  }
})();
