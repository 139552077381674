/**
 * Photo view dialog controller
 */

(function() {
  'use strict';

  angular
    .module('app.itemDetail')
    .controller('ItemPhotoViewController', ItemPhotoViewController);

  ItemPhotoViewController.$inject = ['$mdDialog', 'photoService'];

  function ItemPhotoViewController($mdDialog, photoService) {
    var vm = this;

    vm.cancel = cancel;
    vm.download = download;
    vm.remove = remove;

    /**
     * Closes the dialog
     */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * Downloads the photo
     */
    function download() {
      photoService.downloadPhoto(vm.customerId, vm.itemId, vm.photo.id, 'attachment');
    }

    /**
     * Closes the dialog and resolves with an intention to delete the photo
     */
    function remove() {
      $mdDialog.hide('delete');
    }
  }
})();
