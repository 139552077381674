/**
 * Authentication service
 */

(function() {
  'use strict';

  angular
    .module('app.core.authentication')
    .factory('authenticationService', authenticationService);

  authenticationService.$inject = ['$http', '$q', 'jwtHelper', 'store'];

  function authenticationService($http, $q, jwtHelper, store) {
    return {
      getCustomerId: getCustomerId,
      isAuthenticated: isAuthenticated,
      login: login,
      logout: logout
    };

    /**
     * Gets the authenticated customer's ID from their authentication token
     */
    function getCustomerId() {
      var token = store.get('token');

      // Make sure the user has a valid authentication token
      if (!isAuthenticated()) return false;

      return jwtHelper.decodeToken(token).id;
    }

    /**
     * Determines whether the user has a valid authentication token
     */
    function isAuthenticated() {
      var token = store.get('token');

      return token && !jwtHelper.isTokenExpired(token);
    }

    /**
     * Gets an authentication token from the API and stores it locally
     */
    function login(email, password) {
      var deferred = $q.defer();

      $http
        .post('https://api.echo.phenixjewellery.com/login/customer', {
          email: email,
          password: password
        })
        .then(function(response) {
          store.set('token', response.data.token);
          deferred.resolve();
        })
        .catch(function(error) {
          deferred.reject(error);
        });

      return deferred.promise;
    }

    /**
     * Removes the user's authentication token from local storage
     */
    function logout() {
      store.remove('token');
    }
  }
})();
