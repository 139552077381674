/**
 * Login page configuration
 */

(function() {
  'use strict';

  angular
    .module('app.login')
    .config(config);

  config.$inject = ['$stateProvider'];

  function config($stateProvider) {
    $stateProvider.state('login', {
      url: '/login',
      component: 'login',
      data: { title: 'Log in' }
    });
  }
})();
