/**
 * Photo service
 */

(function() {
  'use strict';

  angular
    .module('app.core.photo')
    .factory('photoService', photoService);

  photoService.$inject = ['$resource', '$window', 'Upload'];

  function photoService($resource, $window, Upload) {
    var Photo = $resource('https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/photos/:photoId',
      null,
      {
        generateDownloadToken: {
          method: 'PUT',
          url: 'https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/photos/:photoId/download-token'
        },
        getData: {
          method: 'GET',
          url: 'https://api.echo.phenixjewellery.com/customers/:customerId/items/:itemId/photos/:photoId/data'
        }
      }
    );

    return {
      deletePhoto: deletePhoto,
      deletePhotos: deletePhotos,
      downloadPhoto: downloadPhoto,
      getPhotoData: getPhotoData,
      uploadPhoto: uploadPhoto
    };

    /**
     * Calls the API to delete a photo
     */
    function deletePhoto(customerId, itemId, photoId) {
      return Photo
        .delete({
          customerId: customerId,
          itemId: itemId,
          photoId: photoId
        })
        .$promise;
    }

    /**
     * Calls the API to delete an item's photos
     */
    function deletePhotos(customerId, itemId) {
      return Photo
        .delete({
          customerId: customerId,
          itemId: itemId
        })
        .$promise;
    }

    /**
     * Calls the API to generate a download token and downloads a photo file
     */
    function downloadPhoto(customerId, itemId, photoId, disposition) {
      switch (disposition) {
        case 'attachment':
          return Photo
            .generateDownloadToken(
              {
                customerId: customerId,
                itemId: itemId,
                photoId: photoId
              },
              {}
            )
            .$promise
            .then(function(downloadToken) {
              var url = 'https://api.echo.phenixjewellery.com/customers/'
                + customerId
                + '/items/'
                + itemId
                + '/photos/'
                + photoId
                + '/file?token='
                + downloadToken.token
                + '&disposition=attachment';

              // Redirect the current window to the file's URL
              $window.location.assign(url);
            });
        case 'inline':
          // Open a new window
          var newWindow = $window.open('about:blank', '_blank');

          return Photo
            .generateDownloadToken(
              {
                customerId: customerId,
                itemId: itemId,
                photoId: photoId
              },
              {}
            )
            .$promise
            .then(function(downloadToken) {
              var url = 'https://api.echo.phenixjewellery.com/customers/'
                + customerId
                + '/items/'
                + itemId
                + '/photos/'
                + photoId
                + 'file/?token='
                + downloadToken.token
                + '&disposition=inline';

              // Redirect the new window to the file's URL
              newWindow.location.href = url;
            });
        default:
          throw new Error('Invalid disposition');
      }
    }

    /**
     * Gets a photo's Base64-encoded JPEG data from the API
     */
    function getPhotoData(customerId, itemId, photoId) {
      return Photo
        .getData({
          customerId: customerId,
          itemId: itemId,
          photoId: photoId
        })
        .$promise
        .then(function(response) {
          return response.data;
        })
    }

    /**
     * Calls the API to upload a photo
     */
    function uploadPhoto(customerId, itemId, photoFile) {
      photoFile.progress = 0;

      return Upload
        .upload({
          url: 'https://api.echo.phenixjewellery.com/customers/' + customerId + '/items/' + itemId + '/photos',
          data: { photo: photoFile }
        })
        .then(
          function(response) {
            return response.data;
          },
          function(error) {
          },
          function(event) {
            photoFile.progress = Math.min(100, parseInt(100.0 * event.loaded / event.total))
          }
        );
    }
  }
})();
