/**
 * Home page component
 */

(function() {
  'use strict';

  angular
    .module('app.index')
    .component('index', {
      templateUrl: 'index/index.template.html'
    });
})();
